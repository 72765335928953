import { thunk } from "easy-peasy";
import {
	deleteWorkScopeApiCall,
	deleteWorkScopeToolApiCall,
	getWorkScopeFilePreviewApiCall,
	getWorkScopeListAPICall,
	getWorkScopeMasterToolListAPICall,
	getWorkScopeSelectionOptionsApiCall,
	getWorkScopeToolListAPICall,
	processWorkScopeFileApiCall,
	uploadWorkScopeFileApiCall,
	upsertWorkScopeAPICall,
	upsertWorkScopeFileAPICall,
	upsertWorkScopeToolAPICall,
} from "../../api/workscope";
import { connectToSignalRHub } from "../../utilities/azure";
import { uploadMasterToolFileToAzureBlobStorage } from "../../api/file";
import { DefaultError, ApiError } from "@cfbs/js-utility";
import { exportWorkScopeToolListToCsvFile } from "../../utilities/file";

function transformWorkScopeData(data) {
	const itemsByName = new Map();

	const sortedData = [...data].sort((a, b) => a.level - b.level);

	sortedData.forEach((item) => {
		const existingItem = itemsByName.get(item.workscopename);

		if (!existingItem) {
			itemsByName.set(item.workscopename, {
				id: item.id,
				name: item.workscopename,
				hastoolsflag: item.hastoolsflag || 0,
				level: item.level,
				children: [],
			});
		}
	});

	sortedData.forEach((item) => {
		if (item.wsparentid) {
			const parentItem = data.find((d) => d.id === item.wsparentid);
			if (parentItem) {
				const parent = itemsByName.get(parentItem.workscopename);
				const child = itemsByName.get(item.workscopename);

				if (
					parent &&
					child &&
					!parent.children.some((c) => c.name === child.name)
				) {
					parent.children.push(child);
				}
			}
		}
	});

	return Array.from(itemsByName.values()).filter((item) => item.level === 1);
}

const thunks = {
	getWorkScopeThunk: thunk(async (actions, payload, helpers) => {
		actions.setLoadingAction(true);

		try {
			const response = await getWorkScopeListAPICall({
				enginefamilyid: payload.enginefamilyid,
				enginevariantid: payload.enginevariantid,
				workscopeid: "",
				user: helpers.getState().user.authenticatedUser.email,
			});
			actions.setWorkScopeListAction(response.data);

			actions.setSelectedDataAction(transformWorkScopeData(response.data));
		} catch (err) {
			console.log(err);
			actions.setAlertThunk({
				type: "error",
				message: err.response.data,
			});
		}

		actions.setLoadingAction(false);
	}),

	deleteWorkScopeThunk: thunk(async (actions, _, helpers) => {
		actions.setLoadingAction(true);

		try {
			await deleteWorkScopeApiCall({
				id: helpers.getState().workscope.input.id,
			});

			const response = await getWorkScopeListAPICall({
				enginefamilyid: helpers.getState().workscope.input.enginefamilyid,
				enginevariantid: helpers.getState().workscope.input.enginevariantid,
				user: helpers.getState().user.authenticatedUser.email,
			});
			actions.setWorkScopeListAction(response.data);

			actions.setSelectedDataAction(transformWorkScopeData(response.data));

			actions.setWorkScopeInputAction({
				...helpers.getState().workscope.input,
				workscopename: "",
				wsparentid: "",
			});

			actions.setAlertThunk({
				type: "success",
				message: "Work Scope deleted successfully",
			});
		} catch (err) {
			console.log(err);
			actions.setAlertThunk({
				type: "error",
				message: err.response.data,
			});
		}
		actions.setLoadingAction(false);
	}),

	deleteWorkScopeToolThunk: thunk(async (actions, _, helpers) => {
		actions.setLoadingAction(true);

		try {
			await deleteWorkScopeToolApiCall({
				id: _,
				// workpackageid: _,
				workscopeid: helpers.getState().workscope.input.id,
			});
			const response = await getWorkScopeToolListAPICall({
				workscopeid: helpers.getState().workscope.input.id,
				user: helpers.getState().user.authenticatedUser,
			});
			actions.setWorkScopeToolListAction(response.data);
			actions.setAlertThunk({
				type: "success",
				message: "Work Scope Tool deleted successfully",
			});
		} catch (err) {
			console.log(err);
			actions.setAlertThunk({
				type: "error",
				message: err.response.data,
			});
		}
		actions.setLoadingAction(false);
	}),

	upsertWorkScopeToolThunk: thunk(async (actions, _, helpers) => {
		actions.setLoadingAction(true);

		try {
			await upsertWorkScopeToolAPICall({
				workscopeid: helpers.getState().workscope.input.id,
				...helpers.getState().workscope.toolInput,
			});

			const response = await getWorkScopeToolListAPICall({
				workscopeid: helpers.getState().workscope.input.id,
				user: helpers.getState().user.authenticatedUser,
			});
			actions.setWorkScopeToolListAction(response.data);

			actions.setAlertThunk({
				type: "success",
				message: "Work Scope added successfully",
			});
		} catch (err) {
			console.log(err);
			actions.setAlertThunk({
				type: "error",
				message: err.response.data,
			});
		}
		actions.setLoadingAction(false);
	}),

	getWorkScopeToolsThunk: thunk(async (actions, _, helpers) => {
		actions.setLoadingAction(true);

		try {
			const response = await getWorkScopeToolListAPICall({
				workscopeid: _.id,
				user: helpers.getState().user.authenticatedUser,
			});
			actions.setWorkScopeToolListAction(response.data);
		} catch (err) {
			console.log(err);
			actions.setAlertThunk({
				type: "error",
				message: err.response.data,
			});
		}

		actions.setLoadingAction(false);
	}),

	getWorkScopeMasterToolsThunk: thunk(async (actions, _, helpers) => {
		actions.setLoadingAction(true);

		try {
			const response = await get({
				workscopeid: _.id,
				user: helpers.getState().user.authenticatedUser,
			});
			actions.setWorkScopeListAction(response.data);
		} catch (err) {
			console.log(err);
			actions.setAlertThunk({
				type: "error",
				message: err.response.data,
			});
		}

		actions.setLoadingAction(false);
	}),

	upsertWorkScopeThunk: thunk(async (actions, payload, helpers) => {
		actions.setLoadingAction(true);

		try {
			await upsertWorkScopeAPICall(
				payload
					? payload
					: {
							...helpers.getState().workscope.input,
					  }
			);

			// actions.getWorkScopeThunk({
			// 	// enginefamilyid: helpers.getState().workscope.input.enginefamilyid,
			// 	// enginevariantid: helpers.getState().workscope.input.enginevariantid,
			// 	enginefamilyid: 1,
			// 	enginevariantid: 1,
			// });

			actions.setAlertThunk({
				type: "success",
				message: "Work Scope added successfully",
			});
		} catch (err) {
			console.log(err);
			actions.setAlertThunk({
				type: "error",
				message: err.response.data,
			});
		}
		actions.setLoadingAction(false);
	}),

	uploadWorkScopeFileThunk: thunk(async (actions, file, helpers) => {
		actions.setShowUploadWorkScopeFileModalAction(false);
		actions.setIsUploadingFileAction(true);

		const connection = await connectToSignalRHub(
			process.env.REACT_APP_BACKEND_BASE_URL,
			helpers.getState().user.authenticatedUser.email,
			{
				onreconnecting: (error) =>
					actions.setIsReconnectingToAzureSignalRAction(true),
				onreconnected: (connectionId) =>
					actions.setIsReconnectingToAzureSignalRAction(false),
			}
		);

		try {
			const sheetName = "workscope";
			const signalRMethodName = "workScopeFileUpload";

			const response = await uploadMasterToolFileToAzureBlobStorage({
				base64: file.base64,
				filename: file.name,
				mimeType:
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
				sheetName,
			});
			actions.setTotalToolsToImportAction(response.data);

			connection.on(signalRMethodName, (processedTool) => {
				actions.setTotalToolsImportedAction(processedTool.totalToolsImported);
				actions.setTotalToolsNotImportedAction(
					processedTool.totalToolsNotImported
				);
			});

			let currentRowParsingIndex = 1;
			let hasFinishedUploading = false;
			let totalToolsImported = 0;
			let totalToolsFailed = 0;

			while (hasFinishedUploading === false) {
				const uploadProgress = await uploadWorkScopeFileApiCall({
					mimeType:
						"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
					name: file.name,
					signalRMethodName,
					currentRowParsingIndex,
					numToolsPerBatch: 100,
					sheetName,
					totalToolsImported,
					totalToolsFailed,
					totalTools: response.data,
					enginefamily: helpers.getState().workscope.input.enginefamily,
					enginefamilyid: helpers.getState().workscope.input.enginefamilyid,
					enginevariant: helpers.getState().workscope.input.enginevariant,
					enginevariantid: helpers.getState().workscope.input.enginevariantid,
				});
				currentRowParsingIndex = uploadProgress.currentRowParsingIndex;
				hasFinishedUploading = uploadProgress.hasFinishedUploading;
				totalToolsImported = uploadProgress.totalToolsImported;
				totalToolsFailed = uploadProgress.totalToolsNotImported;
			}

			actions.getWorkScopeFilePreviewThunk({
				engineFamilyId: helpers.getState().workscope.input.enginefamilyid,
				engineVariantId: helpers.getState().workscope.input.enginevariantid,
			});

			actions.setFilePreviewModalVisibleAction(true);

			actions.setAlertThunk({
				type: "success",
				message: "File uploaded successfully",
			});
		} catch (err) {
			console.error(err);

			let error = new DefaultError(DefaultError.message(err));

			if (ApiError.isApiError(err))
				error = new ApiError(ApiError.default(err), err.response.status);

			actions.setAlertThunk({
				type: "error",
				message:
					error.message ||
					"An error occurred while uploading master tools from csv file",
			});

			actions.setShowUploadWorkScopeFileModalAction(true);
		}

		actions.setIsUploadingFileAction(false);
		connection && connection.stop();
	}),
	getWorkScopeMasterToolListThunk: thunk(
		async (actions, pageNumber, helpers) => {
			actions.setLoadingAction(true);

			try {
				const {
					currWorkScopeMasterToolListPageNumber,
					workScopeMasterToolListSearchInput,
					engineInput,
					engineModelInput,
					workScopeNameInput,
				} = helpers.getState().workscope;

				const list = await getWorkScopeMasterToolListAPICall({
					PageNumber: pageNumber || currWorkScopeMasterToolListPageNumber,
					PageSize: 9,
					searchpartnumber: workScopeMasterToolListSearchInput,
					wsname: workScopeNameInput,
					wsengine: engineInput,
					wsenginemodel: engineModelInput,
				});

				actions.setWorkScopeListAction(list);
			} catch (err) {
				console.error(err);

				let error = new DefaultError(DefaultError.message(err));

				if (ApiError.isApiError(err))
					error = new ApiError(ApiError.default(err), err.response.status);

				actions.setAlertThunk({
					type: "error",
					message:
						error.message ||
						"An error occurred while uploading master tools from csv file",
				});
			}

			actions.setLoadingAction(false);
		}
	),
	getWorkScopeSelectionOptionsThunk: thunk(async (actions) => {
		actions.setLoadingAction(true);

		try {
			const options = await getWorkScopeSelectionOptionsApiCall();
			actions.setWorkScopeSelectionOptionsAction(options);
		} catch (err) {
			console.error(err);

			let error = new DefaultError(DefaultError.message(err));

			if (ApiError.isApiError(err))
				error = new ApiError(ApiError.default(err), err.response.status);

			actions.setAlertThunk({
				type: "error",
				message:
					error.message ||
					"An error occurred while uploading master tools from csv file",
			});
		}

		actions.setLoadingAction(false);
	}),
	exportWorkScopeToolListCsvThunk: thunk(async (actions, payload, helpers) => {
		actions.setLoadingAction(true);

		const { engineInput, engineModelInput, workScopeNameInput } =
			helpers.getState().workscope;

		try {
			const list = await getWorkScopeMasterToolListAPICall({
				PageNumber: null,
				PageSize: null,
				wsname: workScopeNameInput,
				wsengine: engineInput,
				wsenginemodel: engineModelInput,
			});

			exportWorkScopeToolListToCsvFile(
				list,
				`WORK_SCOPE_${engineInput}_${engineModelInput}_${workScopeNameInput}`
			);
		} catch (err) {
			console.log(err);
			actions.setAlertThunk({
				type: "error",
				message: err.response.data,
			});
		}

		actions.setLoadingAction(false);
	}),

	getWorkScopeFilePreviewThunk: thunk(async (actions, payload, helpers) => {
		actions.setLoadingAction(true);

		try {
			const response = await getWorkScopeFilePreviewApiCall(
				payload.engineFamilyId,
				payload.engineVariantId
			);
			console.log(response);
			actions.setWorkScopeFilePreviewAction(response);
		} catch (err) {
			console.log(err);
			actions.setAlertThunk({
				type: "error",
				message: err.response.data,
			});
		}

		actions.setLoadingAction(false);
	}),

	processWorkScopeFileThunk: thunk(async (actions, payload, helpers) => {
		actions.setLoadingAction(true);

		try {
			actions.setFilePreviewModalVisibleAction(false);
			await processWorkScopeFileApiCall({
				enginefamilyid: helpers.getState().workscope.input.enginefamilyid,
				enginevariantid: helpers.getState().workscope.input.enginevariantid,
				workScopeData: helpers.getState().workscope.workScopeFilePreview,
				user: helpers.getState().user.authenticatedUser.email,
			});
			actions.setAlertThunk({
				type: "success",
				message: "Work Scope file processed successfully",
			});

			actions.getWorkScopeThunk({
				enginefamilyid: helpers.getState().workscope.input.enginefamilyid,
				enginevariantid: helpers.getState().workscope.input.enginevariantid,
			});

			payload.navigation.navigate("Workscope Tree View");
		} catch (err) {
			console.log(err);
			actions.setAlertThunk({
				type: "error",
				message: err.response.data,
			});
		}

		actions.setLoadingAction(false);
	}),
};

export default thunks;
