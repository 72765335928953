// TreeView.js
import React, { useEffect, useState, useRef } from "react";
import { View, ScrollView, StyleSheet, ActivityIndicator } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useStoreActions, useStoreState } from "easy-peasy";
import ArrangementModal from "../Modal/ArrangmentModal";
import Page from "../Page/Page";
import Col from "../../presentational/Col";
import DragDropTreeItem from "./DragDropTreeItem";
import {
	addToTarget,
	findItemById,
	removeItemById,
} from "../../../utilities/treeUtils";
import ContentView from "./ContentView.js";
import Button from "../../presentational/Button";
import Input from "../../presentational/Input.js";
import Center from "../../presentational/Center";
import Alerts from "../Alerts.js";

const TreeView = () => {
	const scrollViewRef = useRef(null);
	const [visible, setVisible] = useState(false);

	const [showLoadingMessage, setShowLoadingMessage] = useState(false);
	const [loadingStartTime, setLoadingStartTime] = useState(null);

	useEffect(() => {
		if (loading) {
			setLoadingStartTime(new Date());
		} else {
			setLoadingStartTime(null);
			setShowLoadingMessage(false);
		}
	}, [loading]);

	useEffect(() => {
		let timer;
		if (loadingStartTime) {
			timer = setTimeout(() => {
				setShowLoadingMessage(true);
			}, 5000);
		}

		return () => clearTimeout(timer);
	}, [loadingStartTime, visible]);

	const [data, setData] = useState([]);
	const [backupData, setBackupData] = useState(null);
	const [selectedId, setSelectedId] = useState(null);

	const [expandedIds, setExpandedIds] = useState(new Set());
	const [readOnlyExpandedIds, setReadOnlyExpandedIds] = useState(new Set());

	const [draggingId, setDraggingId] = useState(null);
	const [potentialParentId, setPotentialParentId] = useState(null);

	// Positions => used for collision detection
	const [positions, setPositions] = useState({});

	// Scrolling
	const [scrollEnabled, setScrollEnabled] = useState(true);

	// Track changes in arrangement
	const [changes, setChanges] = useState([]);
	const [showArrangementModal, setShowArrangementModal] = useState(false);
	const [loadingArrangement, setLoadingArrangement] = useState(false);

	const [workScopeInput, setWorkScopeInput] = useState({
		enginefamily: "",
		enginefamilyid: "",
		enginevariant: "",
		enginevariantid: "",
	});

	// Store
	const {
		workscope,
		file: { engineFamilyList, engineVariantList },
		status: { loading },
	} = useStoreState((state) => state);
	const {
		getWorkScopeThunk,
		getWorkPackageThunk,
		setWorkScopeExpandableInputAction,
		upsertWorkScopeThunk,
	} = useStoreActions((actions) => actions);

	// Whenever workscope.selectedData changes, update local tree
	useEffect(() => {
		if (Array.isArray(workscope.selectedData)) {
			setData(workscope.selectedData);
		}
	}, [workscope.selectedData]);

	// Expand toggles
	const toggleExpanded = (id) => {
		setExpandedIds((prev) => {
			const next = new Set(prev);
			next.has(id) ? next.delete(id) : next.add(id);
			return next;
		});
	};
	const toggleReadOnlyExpanded = (id) => {
		setReadOnlyExpandedIds((prev) => {
			const next = new Set(prev);
			next.has(id) ? next.delete(id) : next.add(id);
			return next;
		});
	};

	// Scroll
	const handleScroll = (evt) => {
		if (scrollViewRef.current) {
			scrollViewRef.current.currentContentOffset =
				evt.nativeEvent.contentOffset.y;
		}
	};

	// For measuring item positions
	const updatePositions = (id, position) => {
		setPositions((prev) => ({ ...prev, [id]: position }));
	};

	// Drag
	const handleDragStart = (id) => {
		// Avoid circular JSON errors by removing references or using a safe clone
		// For simplicity, assume data has no circular refs
		setBackupData(JSON.parse(JSON.stringify(data)));
		setDraggingId(id);
		setScrollEnabled(false);
	};

	// const handleDragEnd = (draggedId, dropTargetId) => {
	// 	setDraggingId(null);
	// 	setPotentialParentId(null);
	// 	setScrollEnabled(true);

	// 	// If invalid or no change
	// 	if (!dropTargetId || draggedId === dropTargetId) {
	// 		if (backupData) setData(backupData);
	// 		return;
	// 	}

	// 	const draggedItem = findItemById(data, draggedId);
	// 	if (!draggedItem) return;

	// 	// Remove from old location
	// 	let newData = removeItemById(data, draggedId);

	// 	// Insert under target
	// 	newData = addToTarget(newData, dropTargetId, draggedItem);

	// 	// Expand the drop target
	// 	if (!expandedIds.has(dropTargetId)) {
	// 		setExpandedIds((prev) => {
	// 			const next = new Set(prev);
	// 			next.add(dropTargetId);
	// 			return next;
	// 		});
	// 	}

	// 	setData(newData);

	// 	// Mark as changed
	// 	const updatedItem = {
	// 		...draggedItem,
	// 		wsparentid: dropTargetId,
	// 	};
	// 	setChanges((prev) => {
	// 		const filtered = prev.filter((c) => c.id !== updatedItem.id);
	// 		return [...filtered, updatedItem];
	// 	});
	// };

	// Review changes => show the modal

	const handleDragEnd = (draggedId, dropTargetId) => {
		setDraggingId(null);
		setPotentialParentId(null);
		setScrollEnabled(true);

		// Basic validation
		if (!dropTargetId || draggedId === dropTargetId) {
			if (backupData) setData(backupData);
			return;
		}

		const draggedItem = findItemById(data, draggedId);
		if (!draggedItem) return;

		// Simple remove and add - no complex logic
		let newData = removeItemById(data, draggedId);
		newData = addToTarget(newData, dropTargetId, draggedItem);

		setData(newData);

		// Mark as changed
		const updatedItem = {
			...draggedItem,
			wsparentid: dropTargetId,
		};

		setChanges((prev) => {
			const filtered = prev.filter((c) => c.id !== updatedItem.id);
			return [...filtered, updatedItem];
		});
	};

	const handleReviewChanges = () => {
		setShowArrangementModal(true);
	};

	const handleUndoAll = () => {
		if (backupData) setData(backupData);
		setChanges([]);
		setShowArrangementModal(false);
	};

	const handleSaveArrangement = async () => {
		setLoadingArrangement(true);
		try {
			for (const changedItem of changes) {
				await upsertWorkScopeThunk({
					...workscope.list.find((ws) => ws.id === changedItem.id),
					description: "",
					wsparentid: changedItem.wsparentid,
				});
			}
			console.log("Arrangement saved. Items updated:", changes);
			setChanges([]);
			setShowArrangementModal(false);
		} catch (err) {
			console.log("Failed saving arrangement:", err);
		} finally {
			setLoadingArrangement(false);
		}
	};

	const findSelectedItem = (items, id) => {
		for (const node of items) {
			if (node.id === id) return node;
			if (node.children) {
				const found = findSelectedItem(node.children, id);
				if (found) return found;
			}
		}
		return null;
	};

	return (
		<SafeAreaView style={styles.container}>
			<Alerts />
			{loading ? (
				<Center.Screen>
					{showLoadingMessage && (
						<Text
							style={{
								fontFamily: "Barlow_600SemiBold",
								fontSize: 30,
								position: "absolute",
								top: "35%",
							}}
						>
							This could take a few moments...
						</Text>
					)}

					<ActivityIndicator size="large" color="#0088ce" />
				</Center.Screen>
			) : (
				<>
					{/* The read-only arrangement modal */}
					<ArrangementModal
						visible={showArrangementModal}
						changes={changes}
						onClose={() => setShowArrangementModal(false)}
						onSave={handleSaveArrangement}
						onUndo={handleUndoAll}
						loading={loadingArrangement}
						data={data}
						readOnlyExpandedIds={readOnlyExpandedIds}
						toggleReadOnlyExpanded={toggleReadOnlyExpanded}
					/>

					<View style={styles.mainContent}>
						{/* LEFT: Draggable Tree */}
						<View style={styles.treeOuterContainer}>
							<Col>
								<Input
									width="99%"
									label="Engine Model"
									placeholder="Enter Engine Model"
									onSelect={(name) => {
										setWorkScopeExpandableInputAction({
											...workscope.expandableInput,
											enginefamily: name.EngineFamily,
											enginefamilyid: name.EngineFamilyID,
										});
									}}
									value={workscope.expandableInput?.enginefamily}
									// uploadEngineModel}
									required
									editable={true}
									dropdown
									dropdownChoices={engineFamilyList}
									selectedItem={(item) => item.EngineFamily}
									rowTextForSelection={(item) => item.EngineFamily}
								/>
							</Col>
							<Col>
								<Input
									width="99%"
									label="Engine Variant"
									placeholder="Enter Engine Variant"
									onSelect={(name) => {
										setWorkScopeExpandableInputAction({
											...workscope.expandableInput,
											enginevariant: name.EngineVariant,
											enginevariantid: name.EngineVariantID,
										});

										getWorkScopeThunk({
											enginefamilyid: workscope.expandableInput.enginefamilyid,
											enginevariantid: name.EngineVariantID,
										});
									}}
									value={workscope.expandableInput?.enginevariant}
									required
									editable={
										workscope.expandableInput.enginefamilyid ? true : false
									}
									dropdown
									dropdownChoices={engineVariantList.filter(
										(item) =>
											item.EngineFamilyID ==
											workscope.expandableInput.enginefamilyid
									)}
									selectedItem={(item) => item.EngineVariant}
									rowTextForSelection={(item) => item.EngineVariant}
								/>
							</Col>
							{changes.length > 0 && (
								<Col>
									<Button
										fullWidth
										onPress={handleReviewChanges}
										color="#1976d2"
										style={{ padding: 8, borderRadius: 8 }}
									>
										Review Changes
									</Button>
								</Col>
							)}
							<ScrollView
								ref={scrollViewRef}
								style={{ flex: 1 }}
								contentContainerStyle={{ flexGrow: 1 }}
								onScroll={handleScroll}
								scrollEventThrottle={16}
								scrollEnabled={scrollEnabled}
							>
								{data.map((item) => (
									<DragDropTreeItem
										key={item.id}
										item={item}
										level={0}
										selectedId={selectedId}
										setSelectedId={setSelectedId}
										expandedIds={expandedIds}
										toggleExpanded={toggleExpanded}
										onDragStart={handleDragStart}
										onDragEnd={handleDragEnd}
										isDragging={draggingId}
										potentialParentId={potentialParentId}
										setPotentialParentId={setPotentialParentId}
										positions={positions}
										updatePositions={updatePositions}
										data={data}
										setData={setData}
										scrollViewRef={scrollViewRef}
									/>
								))}
							</ScrollView>
						</View>

						{/* RIGHT: Content View */}
						<View style={styles.contentView}>
							<ContentView selectedItem={findSelectedItem(data, selectedId)} />
						</View>
					</View>
				</>
			)}
		</SafeAreaView>
	);
};

export default TreeView;

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "#fff",
	},
	mainContent: {
		flex: 1,
		flexDirection: "row",
		backgroundColor: "#fff",
		minHeight: "100%",
	},
	treeOuterContainer: {
		width: 400,
		backgroundColor: "#fff",
		borderRightWidth: 1,
		borderRightColor: "#e0e0e0",
		minHeight: "100%",
	},
	contentView: {
		flex: 1,
		backgroundColor: "#fff",
	},
});
