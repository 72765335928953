import { axios } from ".";
import { convertJsonToQueryString } from "../utilities/general";

const fileAxios = axios.create({
	baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/file`,
});

function getFileMappingAPICall(queryString) {
	return fileAxios({
		method: "get",
		url: `/mapping/get?${convertJsonToQueryString(queryString)}`,
	});
}

function upsertFileMappingAPICall(data) {
	return fileAxios({ method: "put", url: "/mapping/upsert", data });
}

function getOEMListAPICall(queryString) {
	return fileAxios({
		method: "get",
		url: `/oem/list/get`,
	});
}

function getEngineFamilyListAPICall(queryString) {
	return fileAxios({
		method: "get",
		url: `/engine/family/list/get`,
	});
}

function getEngineVariantListAPICall(queryString) {
	return fileAxios({
		method: "get",
		url: `/engine/variant/list/get`,
	});
}

function getOEMToEngineFamilyListAPICall() {
	return fileAxios({ method: "get", url: "/oemtoenginefamily/list/get" });
}

function upsertOEMAPICall(data) {
	return fileAxios({ method: "put", url: "/oem/upsert", data });
}

function upsertEngineFamilyAPICall(data) {
	return fileAxios({ method: "put", url: "/engine/family/upsert", data });
}

function upsertEngineVariantAPICall(data) {
	return fileAxios({ method: "put", url: "/engine/variant/upsert", data });
}

function upsertOEMToEngineFamilyAPICall(data) {
	return fileAxios({ method: "put", url: "/oemtoenginefamily/upsert", data });
}

function upsertOEMHeadersAPICall(data) {
	return fileAxios({ method: "put", url: "/oemheaders/upsert", data });
}

function getOEMHeadersAPICall(queryString) {
	return fileAxios({
		method: "get",
		url: `/oemheaders/get?${convertJsonToQueryString(queryString)}`,
	});
}

function deleteFileMappingAPICall(queryString) {
	return fileAxios({
		method: "delete",
		url: `/mapping/delete?${convertJsonToQueryString(queryString)}`,
	});
}

function uploadMasterToolGeneratedCSVFile(data) {
	return fileAxios({ method: "put", url: "/csv/upload", data });
}

function uploadCrtlFileAPICall(data) {
	return fileAxios({ method: "put", url: "/control/upsert", data });
}

function uploadMasterToolFileToAzureBlobStorage(data) {
	return fileAxios({ method: "put", url: "/blobstorage/upload", data });
}

function completeMasterToolFileUpload(data) {
	return fileAxios({ method: "put", url: "/csv/upload", data });
}

function getFileVersionNumberAPICall(data) {
	return fileAxios({ method: "post", url: "/control/prepare", data });
}

function removeFileMappingAsAdminAPICall(queryString) {
	return fileAxios({
		method: "delete",
		url: `/mapping/remove/admin?${convertJsonToQueryString(queryString)}`,
	});
}

export {
	fileAxios,
	upsertFileMappingAPICall,
	getOEMListAPICall,
	getEngineFamilyListAPICall,
	getEngineVariantListAPICall,
	getOEMToEngineFamilyListAPICall,
	upsertOEMAPICall,
	upsertEngineFamilyAPICall,
	upsertEngineVariantAPICall,
	upsertOEMToEngineFamilyAPICall,
	getFileMappingAPICall,
	upsertOEMHeadersAPICall,
	getOEMHeadersAPICall,
	deleteFileMappingAPICall,
	uploadMasterToolGeneratedCSVFile,
	uploadCrtlFileAPICall,
	uploadMasterToolFileToAzureBlobStorage,
	completeMasterToolFileUpload,
	getFileVersionNumberAPICall,
	removeFileMappingAsAdminAPICall,
};
